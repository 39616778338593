export const pricing = [
    // {
    //   title: 'Starter',
    //   price: '€0',
    //   features: [
    //     '5 questions per month',
    //     'Instant responses',
    //     'Links to Irish tax law',
    //   ],
    //   description: 'Basic plan to test it out',
    //   fancy: false,
    // },
    {
      title: 'Pro',
      price: '€19.99 / month',
      features: [
        'Unlimited questions',
        'Instant responses',
        'Links to Irish tax law',
        'Same-day support',
      ],
      description: 'For business owners and professionals.',
      fancy: true, // Marked as a popular plan
    },
  ];
