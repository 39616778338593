// FeedbackComponent.js
import React, { useState, useEffect } from 'react';
import {
  ThumbUpIcon as ThumbUpIconOutline,
  ThumbDownIcon as ThumbDownIconOutline,
  CheckCircleIcon,
} from '@heroicons/react/outline';
import {
  ThumbUpIcon as ThumbUpIconSolid,
  ThumbDownIcon as ThumbDownIconSolid,
} from '@heroicons/react/solid';

const FeedbackComponent = ({ onSubmitFeedback, existingRating }) => {
  const [rating, setRating] = useState(null); // 'up' or 'down'
  const [feedback, setFeedback] = useState('');
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

  // Initialize rating if feedback was already given
  useEffect(() => {
    if (existingRating) {
      setRating(existingRating);
    }
  }, [existingRating]);

  const handleRating = (type) => {
    setRating(type);
    onSubmitFeedback({ rating: type });
    if (type === 'down') {
      setFeedbackModalOpen(true);
    }
  };

  const handleSubmitFeedback = () => {
    onSubmitFeedback({ feedback });
    setFeedback('');
    setFeedbackModalOpen(false);
    setFeedbackSubmitted(true);
    // Hide the success message after 3 seconds
    setTimeout(() => {
      setFeedbackSubmitted(false);
    }, 3000);
  };

  return (
    <div className="mt-2 flex items-center space-x-2 pl-8 relative">
      <button
        className={`focus:outline-none ${
          rating === 'up' ? 'text-green-500' : 'text-gray-500 hover:text-green-500'
        }`}
        onClick={() => handleRating('up')}
      >
        {rating === 'up' ? (
          <ThumbUpIconSolid className="h-5 w-5" />
        ) : (
          <ThumbUpIconOutline className="h-5 w-5" />
        )}
      </button>
      <button
        className={`focus:outline-none ${
          rating === 'down' ? 'text-red-500' : 'text-gray-500 hover:text-red-500'
        }`}
        onClick={() => handleRating('down')}
      >
        {rating === 'down' ? (
          <ThumbDownIconSolid className="h-5 w-5" />
        ) : (
          <ThumbDownIconOutline className="h-5 w-5" />
        )}
      </button>

      {/* Feedback Modal */}
      {feedbackModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-md w-11/12 max-w-md">
            <h2 className="text-lg font-semibold mb-2">Your Feedback</h2>
            <textarea
              className="w-full p-2 border border-gray-300 rounded mt-2 bg-white text-black"
              placeholder="Enter your feedback here..."
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
            />
            <div className="flex justify-end mt-4 space-x-2">
              <button
                className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
                onClick={() => {
                  setFeedback('');
                  setFeedbackModalOpen(false);
                }}
              >
                Cancel
              </button>
              <button
                className={`px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 ${
                  feedback.trim() === '' ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                onClick={handleSubmitFeedback}
                disabled={feedback.trim() === ''}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Success Message */}
      {feedbackSubmitted && (
        <div className="absolute top-0 left-1/2 transform -translate-x-1/2 mt-10">
          <div className="flex items-center bg-green-500 text-white text-sm font-medium px-4 py-2 rounded shadow-lg">
            <CheckCircleIcon className="h-5 w-5 text-white mr-2" />
            <span>Thank you for your feedback!</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default FeedbackComponent;
