import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, sendEmailVerification } from 'firebase/auth';

const EmailVerification = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const [message, setMessage] = useState('');

  const handleResendVerification = async () => {
    const user = auth.currentUser;
    try {
      await sendEmailVerification(user);
      setMessage('Verification email resent. Please check your inbox.');
    } catch (error) {
      console.error('Error resending verification email:', error);
      setMessage('Error resending verification email. Please try again later.');
    }
  };

  const handleCheckVerification = async () => {
    const user = auth.currentUser;
    await user.reload();
    if (user.emailVerified) {
      navigate('/chat');
    } else {
      setMessage('Email not verified yet. Please check your inbox.');
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center px-4">
      <div className="max-w-md w-full bg-white shadow-md rounded-lg p-6">
        <h2 className="text-2xl text-gray-700 font-semibold text-center mb-4">Verify Your Email</h2>
        <p className="text-gray-700 mb-6 text-center">
          A verification email has been sent to your email address. Please verify your email to access the chat.
        </p>
        {message && (
          <div className="mb-4 text-center text-sm text-red-500">
            {message}
          </div>
        )}
        <button
          onClick={handleResendVerification}
          className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 mb-4"
        >
          Resend Verification Email
        </button>
        <button
          onClick={handleCheckVerification}
          className="w-full bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
        >
          I've Verified My Email
        </button>
      </div>
    </div>
  );
};

export default EmailVerification;