// firebaseConfig.js

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"; // Import getAuth
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from "firebase/functions";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB-B1nVU9v83SvkZx9Ztf12w3k9q8dvZ50",
  authDomain: "auth.taxbot.ie",
  projectId: "taxbot-56592",
  storageBucket: "taxbot-56592.appspot.com",
  messagingSenderId: "423090585548",
  appId: "1:423090585548:web:89858d7a0574daa53f6769",
  measurementId: "G-5Q32BZ370F"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app); // Initialize Firebase Authentication
const db = getFirestore(app);
const functions = getFunctions(app);

// Initialize analytics if needed, not exporting since it's not used elsewhere
getAnalytics(app);

// Export the app and auth
export { app, auth, db, functions };