// MessageList.js
import React, { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeExternalLinks from 'rehype-external-links';
import FeedbackComponent from './FeedbackComponent';
import { db } from '../firebaseConfig';
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  updateDoc,
  doc,
} from 'firebase/firestore';
import '../styles/Chat.css';

const MessageList = ({ messages, isSending, pendingBotMessage, threadId, userId }) => {
  const messagesEndRef = useRef(null);
  const [feedbacks, setFeedbacks] = useState({});

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages, pendingBotMessage]);

  // Fetch feedbacks for current thread and user
  useEffect(() => {
    const fetchFeedbacks = async () => {
      if (threadId && userId) {
        const feedbacksRef = collection(db, 'feedback');
        const q = query(
          feedbacksRef,
          where('threadId', '==', threadId),
          where('userId', '==', userId)
        );
        const querySnapshot = await getDocs(q);
        const feedbacksData = {};
        querySnapshot.forEach((docSnapshot) => {
          const data = docSnapshot.data();
          if (data.rating) {
            feedbacksData[data.messageId] = { ...data, docId: docSnapshot.id };
          }
        });
        setFeedbacks(feedbacksData);
      }
    };
    fetchFeedbacks();
  }, [threadId, userId, messages]);

  // Handle feedback submission
  const handleFeedbackSubmit = async (messageId, feedbackData) => {
    console.log(`Feedback for message ${messageId}:`, feedbackData);
    // Prepare feedback document
    const feedbackDoc = {
      threadId: threadId,
      userId: userId,
      messageId: messageId,
      messageText: messages.find((msg) => msg.id === messageId)?.text || '',
      timestamp: new Date(),
    };

    if (feedbackData.rating) {
      feedbackDoc.rating = feedbackData.rating;
    }

    if (feedbackData.feedback) {
      feedbackDoc.feedback = feedbackData.feedback;
    }

    if (feedbacks[messageId]) {
      // Update existing feedback document
      const docId = feedbacks[messageId].docId;
      const docRef = doc(db, 'feedback', docId);
      await updateDoc(docRef, feedbackDoc);
    } else {
      // Add new feedback document
      const docRef = await addDoc(collection(db, 'feedback'), feedbackDoc);
      feedbackDoc.docId = docRef.id;
    }

    // Update local feedbacks state
    setFeedbacks((prevFeedbacks) => ({
      ...prevFeedbacks,
      [messageId]: feedbackDoc,
    }));
  };

  return (
    <div className="chat-messages">
      {messages.map((message, index) => (
        <div
          key={message.id || index}
          className={`message ${message.sender === 'user' ? 'user' : 'system'}`}
        >
          <ReactMarkdown
            children={message.text}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[
              [
                rehypeExternalLinks,
                { target: '_blank', rel: ['noopener', 'noreferrer'] },
              ],
            ]}
            components={{
              a: ({ node, ...props }) => (
                <a className="text-blue-500 hover:underline" {...props} />
              ),
              p: ({ node, ...props }) => <p {...props} />,
              // ... other components
            }}
          />
          {message.sender === 'system' && (
            <FeedbackComponent
              onSubmitFeedback={(feedbackData) =>
                handleFeedbackSubmit(message.id, feedbackData)
              }
              feedbackGiven={feedbacks[message.id] !== undefined}
              existingRating={feedbacks[message.id]?.rating || null}
            />
          )}
        </div>
      ))}
      {pendingBotMessage && (
        <div className="message system">
          <ReactMarkdown
            children={pendingBotMessage.text}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[
              [
                rehypeExternalLinks,
                { target: '_blank', rel: ['noopener', 'noreferrer'] },
              ],
            ]}
            components={{
              a: ({ node, ...props }) => (
                <a className="text-blue-500 hover:underline" {...props} />
              ),
              p: ({ node, ...props }) => <p {...props} />,
              // ... other components
            }}
          />
          {/* Optionally, you can show a loading indicator here */}
        </div>
      )}
      {isSending && !pendingBotMessage && (
        <div className="message system">
          <span className="loading-dots">
            <div></div>
            <div></div>
            <div></div>
          </span>
        </div>
      )}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default MessageList;