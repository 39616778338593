// src/SignupProcessor.js

import { app } from '../firebaseConfig';
import { getFirestore, collection, addDoc, query, where, getDocs, updateDoc, doc } from 'firebase/firestore';
import { getAuth, createUserWithEmailAndPassword, signInWithCredential, GoogleAuthProvider, sendEmailVerification } from 'firebase/auth';

const db = getFirestore(app);
const auth = getAuth(app);

const createUser = async (email, password, userData) => {
  try {
    let user;
    if (password) {
      // Create the user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      user = userCredential.user;

      await sendEmailVerification(user);
    } else {
      // This is a Google sign-up; user is already signed in
      user = auth.currentUser;
      if (!user) {
        throw new Error('No user is currently signed in.');
      }
    }

    // Proceed to check and update Firestore as before
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('email', '==', email));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      // Add new user data to Firestore
      await addDoc(collection(db, 'users'), {
        uid: user.uid,
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        googleId: user.uid
      });
    } else {
      // User exists, update the existing document
      const existingUserDoc = querySnapshot.docs[0];
      await updateDoc(doc(db, 'users', existingUserDoc.id), {
        googleId: user.uid || existingUserDoc.data().googleId,
        firstName: userData.firstName || existingUserDoc.data().firstName,
        lastName: userData.lastName || existingUserDoc.data().lastName
      });
    }

    console.log('User created or updated with UID:', user.uid);
    return user;
  } catch (error) {
    console.error('Error creating or updating user:', error.message);
    throw error;
  }
};

export { createUser, auth };
