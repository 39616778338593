import React, { useState, useEffect, useContext } from 'react';
import { db } from './firebaseConfig';
import { AuthContext } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, addDoc, onSnapshot } from 'firebase/firestore';
import { getAuth, signOut } from 'firebase/auth';
import checkForActiveSubscription from './utilities/SubscriptionChecker';

const Subscriptions = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const auth = getAuth();

  const formatPrice = (amount, currency) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
    }).format(amount / 100);
  };

  const logout = () => {
    signOut(auth)
      .then(() => {
        navigate('/');
      })
      .catch((error) => {
        console.error('Logout Error:', error);
      });
  };

  useEffect(() => {
    if (!currentUser) {
      navigate('/Welcome');
      return;
    }

    checkForActiveSubscription(currentUser.uid)
      .then((activeSubscriptions) => {
        if (activeSubscriptions.length > 0) {
          console.log('User has active subscriptions:', activeSubscriptions);
          navigate('/chat');
        } else {
          console.log('User does not have active subscriptions.');
        }
      })
      .catch((error) => {
        console.error('Error checking for active subscriptions:', error);
      });

    const fetchProducts = async () => {
      setLoading(true);
      try {
        const productsQuery = query(collection(db, 'products'), where('active', '==', true));
        const querySnapshot = await getDocs(productsQuery);

        const productsPromises = querySnapshot.docs.map(async (productDoc) => {
          let productInfo = productDoc.data();
          productInfo.id = productDoc.id;

          const pricesCollection = collection(productDoc.ref, 'prices');
          const priceQuerySnapshot = await getDocs(pricesCollection);

          if (priceQuerySnapshot.docs.length > 0) {
            const priceDoc = priceQuerySnapshot.docs[0];
            productInfo['priceId'] = priceDoc.id;
            productInfo['priceInfo'] = priceDoc.data();
            return productInfo;
          }
          return null;
        });

        const productsData = (await Promise.all(productsPromises)).filter((product) => product !== null);
        setProducts(productsData);
      } catch (err) {
        console.error('Error fetching products:', err);
        setError('Error fetching products.');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [currentUser, navigate]);

  const startSubscription = async (priceId) => {
    setLoading(true);
    try {
      const checkoutSessionRef = await addDoc(
        collection(db, 'customers', currentUser.uid, 'checkout_sessions'),
        {
          price: priceId,
          allow_promotion_codes: true,
          trial_period_days: 7,
          success_url: `${window.location.origin}/success`,
          cancel_url: `${window.location.origin}/cancel`,
        }
      );

      onSnapshot(checkoutSessionRef, (snap) => {
        const { error, url } = snap.data();
        if (error) {
          setError(`An error occurred: ${error.message}`);
          setLoading(false);
        }
        if (url) {
          window.location.assign(url);
        }
      });
    } catch (err) {
      console.error('Error starting subscription:', err);
      setError('Failed to start subscription.');
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <div className="text-xl font-semibold text-gray-700">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <div className="text-red-500 text-xl font-semibold">{error}</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <header className="bg-white shadow mb-8">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <h1 className="text-3xl font-bold text-gray-900">Choose Your Plan</h1>
          <button
            className="px-4 py-2 bg-red-600 text-white font-semibold rounded-md hover:bg-red-700 transition duration-300"
            onClick={logout}
          >
            Logout
          </button>
        </div>
      </header>

      <main className="flex-grow max-w-4xl mx-auto py-10 px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow-lg rounded-lg p-8 border-t-4 border-blue-500">
          <h2 className="text-2xl font-semibold text-center text-gray-800 mb-4">
            Subscribe to continue
          </h2>
          <p className="text-center text-gray-600 mb-8">
            Have questions? Contact us at{' '}
            <a
              href="mailto:support@taxbot.ie"
              className="text-blue-500 hover:text-blue-700 underline"
            >
              support@taxbot.ie
            </a>
            .
          </p>
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6">
            {products.map((product) => (
              <div
                key={product.id}
                className="relative p-6 border border-gray-200 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 bg-gradient-to-br from-gray-50 to-white"
              >
                {/* Free Trial Badge */}
                <div className="absolute top-0 right-0 mt-4 mr-4">
                  <span className="bg-green-200 text-green-800 text-xs font-bold px-3 py-1 rounded-full shadow-md">
                    7-Day Free Trial
                  </span>
                </div>

                <div className="mb-4 mt-4">
                  <h3 className="text-lg font-bold text-gray-900 mb-2">{product.name}</h3>
                  <ul className="list-disc pl-5 text-gray-700 space-y-1">
                    <li>Unlimited questions</li>
                    <li>Access to Irish tax resources</li>
                    <li>24/7 Support Included</li>
                  </ul>
                </div>

                {product.priceInfo && (
                  <p className="text-gray-800 font-semibold text-lg mb-4">
                    Price: {formatPrice(product.priceInfo.unit_amount, product.priceInfo.currency)}
                  </p>
                )}
                {product.priceId && (
                  <button
                    className="w-full px-4 py-2 bg-blue-500 text-white font-bold rounded-md hover:bg-blue-600 transition duration-300"
                    onClick={() => startSubscription(product.priceId)}
                  >
                    Start 7-Day Free Trial
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      </main>

      <footer className="bg-white shadow mt-10 py-4 text-center">
        <p className="text-gray-500 text-sm">
          &copy; {new Date().getFullYear()} TaxBot. All rights reserved.
        </p>
      </footer>
    </div>
  );
};

export default Subscriptions;
