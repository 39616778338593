// src/AppNavigator.js

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Home';
import Welcome from './Welcome';
import Signup from './login/Signup';
import Chat from './Chat';
import Login from './login/Login';
import PasswordReset from './login/PasswordReset';
import ProtectedRoute from './ProtectedRoute';
import Account from './Account';
import Subscriptions from './Subscriptions'; // Import the Subscriptions component
import ManageSubscription from './ManageSubscription';
import Contact from './Contact';
import EmailVerification from './EmailVerification'
import './styles/style.css';

const AppNavigator = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} title="Home"/>
        <Route path="/contact" element={<Contact />} title="Contact"/>
        <Route path="/welcome" element={<Welcome />} title="Welcome"/>
        <Route path="/signup" element={<Signup />} title="Signup"/>
        <Route 
          path="/chat"
          title="Chat" 
          element={
            <ProtectedRoute>
              <Chat />
            </ProtectedRoute>
          }
        />
        <Route path="/login" element={<Login />} title="Login"/>
        <Route path="/password-reset" element={<PasswordReset />} title="Password Reset"/>
        <Route path="/email-verification" element={<EmailVerification />} title="Email Verification"/>
        {/* New route for account management */}
        <Route 
          path="/account"
          title="Account"
          element={
            <ProtectedRoute>
              <Account />
            </ProtectedRoute>
          }
        />

        {/* New route for subscription management */}
        <Route 
          path="/subscriptions"
          title="Subscriptions"
          element={
            <ProtectedRoute>
              <Subscriptions /> {/* This will be your subscription management component */}
            </ProtectedRoute>
          }
        />
        <Route 
          path="/manage-subscription"
          title="Manage Subscription"
          element={
            <ProtectedRoute>
              <ManageSubscription /> {/* This will be your subscription management component */}
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
};

export default AppNavigator;
